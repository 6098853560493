import React, { Component } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ItemsPage from "./pages/Items";
import Portofolio from "./pages/portofolio"
import SuccessPage from "./pages/success"
import ViewPage from "./pages/view"

export class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Portofolio/>} />
          <Route path="/bihogo" element={<SuccessPage/>} />
          {/* <Route path="/view" element={<ViewPage/>} /> */}
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
