import { LOADING, SAVE_FAILED, SAVE_SUCCESS, GET_FAILED, GET_SUCCESS } from './types'
import axios from "axios"

const endPointUrl = process.env.REACT_APP_API_LINK

export const SaveItem = ({name, email, address, phone}) => dispatch => {

    dispatch({
        type: LOADING,
    })
    const config = {
        headers: {
            "content-type": "application/json"
        }
    }

    const api = endPointUrl + "/api/item"
    const body = JSON.stringify({name, email, address, phone})

    axios.post(api, body, config)
        .then(res => {
            if(res.status === 200) {
                dispatch({
                    type: SAVE_SUCCESS,
                    payload: res,
                })
                window.location = '/success'
            } else {
                dispatch({
                    type: SAVE_FAILED,
                    payload: res.data
                })
            }
        })        
        .catch(err => {
            console.log(err)
        })
}

export const ViewItem = () => async (dispatch)  => {
    console.log("fdkfjdbf")
    dispatch({
        type: LOADING,
    })
    const config = {
        headers: {
            "content-type": "application/json"
        }
    }

    const api = endPointUrl + "/api/item"

    await axios.get(api, config)
        .then(res => {
            if(res.status === 200) {
                dispatch({
                    type: GET_SUCCESS,
                    payload: res.data,
                })
            } else {
                dispatch({
                    type: GET_FAILED,
                    payload: res.data
                })
            }
        })        
        .catch(err => {
            console.log(err)
        })
}