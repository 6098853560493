import React, { useState } from 'react';
import { BsLinkedin, BsGithub, BsTelephoneFill } from "react-icons/bs"
import { MdEmail } from "react-icons/md"
import Rwandapedia from "../Assets/rwandapedia.png"
import Reservation from "../Assets/reservation.png"
import Archive from "../Assets/Archive.png"
import Fs from "../Assets/FS.png"

function Success() {

    const [menu, setMenu] = useState('about')

    const handlerManu = (me) => {
        console.log(me);
    }

    const hash = window.location.hash
    console.log(hash);

    const Experience = [

        {
            date: "Aug 2022 - Present",
            role: "Software Developer",
            company: "AEGIS Trust",
            description: "I provided guidance and support to the development team, assessed performance, managed infrastructure, delivered metrics for informed decisions, evaluated software, designed and updated systems, coached team members and stakeholders in Scrum and agile practices, trained end-users on software use, and educated stakeholders on Scrum principles and responsibilities.",
            stack: [
                "React", "Node",
                "NestJs", "AngularJs"
            ]
        },
        {
            date: "Dec 2020 - Aug 2022",
            role: "Full-stack developer & Business Analyst",
            company: "Dokuma",
            description: "I gathered project requirements, managed continuous integration and deployment, created APIs for web and mobile apps using Java or JavaScript, maintained secure databases, assessed performance, monitored infrastructure, integrated APIs, evaluated software efficiency and responsiveness, and designed and updated software systems while providing end-user training.",
            stack: [
                "ReactjS", "NodejS",
                "VueJs", "Tailwind", "Spring"
            ]
        },
        {
            date: "Mar 2019 - Feb 2020",
            role: "Full-Satck Developer",
            company: "Tech Enfold",
            description: "I developed program logic, created APIs for website and mobile apps using Java, ensured a secure, clean, and user-friendly database (SQL and NoSQL), and collaborated with teams to ensure seamless integration and user-friendly software design.",
            stack: [
                "SCSS", "HTML",
                "ReactJs", "NodeJs",
                "Wordpress",
            ]
        },
        {
            date: "Feb 2018 - Mar 2019",
            role: "Software developer",
            company: "Creative Theme",
            description: "I integrated APIs and designed user-friendly website UIs using ReactJs and Vuejs, leveraging modern frameworks and libraries to create dynamic and interactive user interfaces, while collaborating with back-end development for technical feasibility.",
            stack: [
                "HTML", "scss", "Javascript"
            ]
        },
    ]


    const Projects = [

        {
            name: "Rwandapedia",
            image: [Rwandapedia],
            description: "I build an open, digital platform created to let students, researchers, policymakers, journalists and public servants learn about Rwanda and its transformation journey.",
            link: "http://www.rwandapedia.rw/",
            stacks: [
                "NodeJs", "ReactJs", "Redux", "scss"
            ]
        },
        {
            name: "RCI Reservation Portal",
            image: [Reservation],
            description: "I developed a portal for study visit delegations to conveniently book their trips, including accommodations, transportation, and attractions, thereby facilitating RCI's delegation planning and management.",
            link: "https://reservations.cooperation.rw/institution",
            stacks: [
                "Laravel", "VueJs", "SQL", "Sass"
            ]
        },
        {
            name: "Digital document Archive",
            image: [Archive],
            description: "I created a platform to digitize physical documents related to the Genocide, encompassing images, videos, audio, and papers. This enables the secure archiving of these documents while allowing global access through the platform to interested parties worldwide",
            link: "#",
            stacks: [
                "Laravel", "VueJs", "SQL", "TailWind"
            ]
        },

        {
            name: "Storage Management Application",
            image: [Fs],
            description: "Developed a software application designed specifically to empower rental stores with efficient product management. This application streamlines the entire process, enabling rental businesses to effortlessly catalog their inventory, track availability, generate reports, and provide an enhanced customer experience.",
            link: "#",
            stacks: [
                "React native", "Redux", "Axios",
            ]
        },
        // {
        //     name: "MEIS Application",
        //     image: MEIS,
        //     description: "I developed a portal for study visit delegations to conveniently book their trips, including accommodations, transportation, and attractions, thereby facilitating RCI's delegation planning and management.",
        //     link: "#",
        //     stacks: [
        //         "Laravel", "VueJs", "SQL", "Sass"
        //     ]
        // },
    ]
    return (
        <div className="flex justify-center bg-primary">
            <div className='max-w-6xl w-full lg:grid grid-cols-2 '>
                <div className='lg:fixed top-0 bottom-0 right-0 left-0 overflow-hidden lg:h-screen'>
                    <div className='flex justify-center text-textPrimary  h-full lg:py-24 py-12 px-4'>
                        <div className='max-w-6xl lg:grid grid-cols-2 w-full'>
                            <div className='w-full flex flex-col justify-between'>
                                <div className=''>
                                    <div className=''>
                                        <p className="text-5xl leading-10 text-mainText font-bold">Bihogo Arsene</p>
                                        <p className='text-3xl leading-7 text-mainText font-light mt-4'>Software Developer</p>
                                        <p className='text-xl text-text mt-4'>I specialize in crafting dependable </p>
                                        <p className='text-xl text-text'>digital web platforms and applications.</p>
                                    </div>
                                    <div className='flex flex-col gap-2 my-16 text-lg '>
                                        <a href="#about" onClick={() => setMenu('about')} className={`flex gap-2 items-center hover:text-mainText ${menu === "about" ? `text-mainText` : `text-text`}`}>
                                            <hr className={`hr-transition ${menu === 'about' ? `w-14` : `w-8`}`} />
                                            <p >About</p>
                                        </a>
                                        <a href="#experience" onClick={() => setMenu('experience')} className={`flex gap-2 items-center hover:text-mainText ${menu === "experience" ? `text-mainText` : `text-text`}`}>
                                            <hr className={`hr-transition ${menu === 'experience' ? `w-14` : `w-8`}`} />
                                            <p >Experience</p>
                                        </a>
                                        <a href="#project" onClick={() => setMenu('project')} className={`flex gap-2 items-center hover:text-mainText ${menu === "project" ? `text-mainText` : `text-text`}`}>
                                            <hr className={`hr-transition ${menu === 'project' ? `w-12` : `w-8`}`} />
                                            <p>Project</p>
                                        </a>
                                    </div>
                                </div>
                                <div className='text-text flex flex-col gap-8'>
                                    <div className='flex gap-6 text-text text-2xl'>
                                        <a href="https://www.linkedin.com/in/arsene-bihogo-7139411a7/"><BsLinkedin /></a>
                                        <a href="https://github.com/bhgarsene"><BsGithub /></a>
                                    </div>
                                    <div className='flex gap-6'>
                                        <div className='flex items-center gap-2'>
                                            <MdEmail />
                                            <p className='text-sm'>bhgarsene@gmail.com</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <BsTelephoneFill />
                                            <p className='text-sm'>+250 789 248 248</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
                <div className='  flex flex-col justify-between text-textPrimary'>
                    <section className='lg:pt-24 pt-12' id='about'>
                        <div className='flex flex-col gap-4 text-justify text-text px-4' >
                            <p>With over 5 years of experience in web development, I've cultivated a deep knowledge that enables me to create applications from the ground up and proficiently maintain existing ones. My versatile skill set allows me to take on the complete project lifecycle independently, from conceptualization to deployment, while also collaborating seamlessly within a team environment.</p>
                            <p>Furthermore, my commitment to professional growth is exemplified by my ongoing study of the Project Management Professional (PMP) certification. This journey has introduced me to Agile methodologies, which I now employ to ensure that stakeholders remain consistently satisfied with the projects I undertake. This approach not only enhances project efficiency but also guarantees that the final results align with the expectations and needs of all parties involved.</p>
                            <p>Whether you're seeking an independent expert to lead a project or a collaborative team player to bolster your existing workforce, I am here to provide the specialized skills and dedication needed to drive success in your IT endeavors.</p>
                        </div>
                    </section>
                    <section className='lg:pt-24 pt-12' id='experience'>
                        <div className='flex flex-col'>
                            {
                                Experience.map((dat) => {
                                    return (
                                        <div className='flex w-full text-mainText rounded-xl px-4 py-6 hover:bg-hoverbg cursor-pointer '>
                                            <p className='w-1/3 text-text text-sm mt-1'>{dat.date}</p>
                                            <div className='flex flex-col w-3/4'>
                                                <p className='text-lg font-semibold text-Green'>{dat.role}</p>
                                                <p className='font-semibold'>{dat.company}</p>
                                                <p className='text-text mt-2'>{dat.description}</p>
                                                <div className='grid grid-cols-4 gap-2 mt-4'>
                                                    {
                                                        dat.stack.map((st) => {
                                                            return (
                                                                <div className='bg-textGreen text-center py-1 rounded-full'>
                                                                    <p className='text-xs text-Green'>{st}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>
                    <section className='lg:pt-24 pt-12' id='project'>
                        <div className='flex flex-col'>
                            {
                                Projects.map((dat) => {
                                    return (
                                        <a key={dat.link} href={dat.link} className='flex w-full text-mainText rounded-xl px-4 py-6 gap-6 items-start cursor-pointer'>
                                            <div className='w-1/3 flex mt-1'>
                                                {
                                                    dat.image.map((img) =>
                                                        <img src={img} alt="" srcset="" />
                                                    )
                                                }
                                            </div>
                                            <div className='flex flex-col w-3/4'>
                                                <p className='text-lg font-semibold text-Green'>{dat.name}</p>
                                                {/* <p className='font-semibold'>{dat.company}</p> */}
                                                <p className='text-text mt-2'>{dat.description}</p>
                                                <div className='grid grid-cols-4 gap-2 mt-4'>
                                                    {
                                                        dat.stacks.map((st) => {
                                                            return (
                                                                <div className='bg-textGreen text-center py-1 rounded-full'>
                                                                    <p className='text-xs text-Green'>{st}</p>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )

}

export default Success;