import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
// import Store from "./redux/store";
import 'react-phone-number-input/style.css';
import Reducers from "./redux/reducers/index"
import Middlewares from "./redux/middlewares/index"
import { createStore } from "redux";

const Store = createStore(Reducers, Middlewares);
ReactDOM.render(
	<Provider store={Store}>
		<App />
	</Provider>,
	document.getElementById("root"),
);

