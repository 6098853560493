import React from 'react'
import Logo from "../Assets/logo.jpeg"
import HomeImage from "../Assets/home.png"
import DataImage from "../Assets/data.jpg"
import WorkImage from "../Assets/Work.jpg"

import { MdSecurity, MdEmail } from "react-icons/md"
import { GiProcessor } from "react-icons/gi"
import { AiOutlineCloudSync } from "react-icons/ai"
import { BiSolidPhone } from "react-icons/bi"

export default function portofolio() {
    return (
        <div>
            <section className='flex justify-center border-b border-b-slate-200 fixed top-0 right-0 left-0 bg-white'>
                <div className='max-w-7xl h-16 py-2 px-6 w-full flex justify-between'>
                    <a href="#"> <img src={Logo} alt="" className='h-12' srcset="" /></a>

                    <div className='flex items-center gap-12 pr-32'>
                        <a href="#about">About</a>
                        <a href="#principles">Our Principles</a>
                        <a href="#work">Our Work</a>
                    </div>
                </div>
            </section>
            <section className='flex justify-center' id=''>
                <div className='max-w-7xl grid grid-cols-2 w-full px-6'>
                    <div className='flex flex-col justify-center'>
                        <h1 className='text-6xl font-semibold leading-normal'>Welcome to <span className='text-pprimary font-bold'>DALTECH</span> Pioneering Digitization For a  Better Digital World</h1>
                    </div>
                    <img src={HomeImage} alt="" className="py-20 " srcset="" />
                </div>
            </section>
            <section className=' flex justify-center' id='about'>
                <div className='w-full grid grid-cols-2 '>
                    <div >
                        <img src={DataImage} alt="" className='rounded-r-3xl' srcset="" />
                    </div>
                    <div className='flex flex-col gap-10 justify-center px-32'>
                        <p className='text-base font-'>About Us</p>
                        <div className='flex flex-col gap-4 text-justify'>
                            <p className='text-4xl text-pprimary font-semibold'>Vision</p>
                            <span className='text-base'>
                                To be the pioneering force in transforming information landscapes globally, empowering organizations to seamlessly navigate the digital era through innovative document management solutions. We envision a world where the power of organized and accessible information propels businesses and societies toward unparalleled efficiency and progress.
                            </span>
                        </div>
                        <div className='flex flex-col gap-4 text-justify'>
                            <p className='text-4xl text-pprimary font-semibold'>Mission</p>
                            <span className='text-base'>
                                Empowering Progress Through Digital Precision:
                                At Daltech, our mission is to revolutionize document management and archival processes. We are committed to providing cutting-edge, user-friendly technologies that simplify document workflows and enhance accessibility. Through our relentless pursuit of innovation, we strive to digitize and optimize information ecosystems, unlocking untapped potential for businesses, governments, and institutions.
                            </span>
                        </div>
                    </div>
                </div>
            </section>
            <section className='flex justify-center py-32 bg-pbluebg' id='principles'>
                <div className='max-w-7xl flex flex-col items-center w-full px-6 gap-4'>
                    <p className='text-pprimary font-semibold'>Our Principles:</p>
                    <span className='text-3xl text-pblact font-semibold'>We are driven by the following principles:</span>
                    <div className='grid grid-cols-3 w-full mt-12 gap-8'>
                        <div className='p-8 rounded-3xl shadow-md hover:border-pprimary hover:border border border-green-50 w-full flex flex-col gap-6 bg-white'>
                            <div className='h-10 w-10 rounded-full bg-pprimary text-white flex items-center justify-center text-xl'>
                                <MdSecurity />
                            </div>
                            <p className='text-3xl text-pblact font-semibold'>Innovation</p>
                            <span className='text-gray'>Constantly pushing boundaries to develop solutions that redefine the document management landscape</span>
                        </div>
                        <div className='p-8 rounded-3xl shadow-md hover:border-pprimary hover:border border border-green-50 w-full flex flex-col gap-6 bg-white'>
                            <div className='h-10 w-10 rounded-full bg-pprimary text-white flex items-center justify-center text-xl'>
                                <MdSecurity />
                            </div>
                            <p className='text-3xl text-pblact font-semibold'>Simplicity</p>
                            <span className='text-gray'> Creating intuitive and user-friendly platforms that empower users to effortlessly manage, access, and collaborate on their digital archives</span>
                        </div>
                        <div className='p-8 rounded-3xl shadow-md hover:border-pprimary hover:border border border-green-50 w-full flex flex-col gap-6 bg-white'>
                            <div className='h-10 w-10 rounded-full bg-pprimary text-white flex items-center justify-center text-xl'>
                                <MdSecurity />
                            </div>
                            <p className='text-3xl text-pblact font-semibold'>Sustainability</p>
                            <span className='text-gray'>Championing eco-friendly practices in digitization, reducing the environmental footprint of traditional document processes.</span>
                        </div>
                        <div className='p-8 rounded-3xl shadow-md hover:border-pprimary hover:border border border-green-50 w-full flex flex-col gap-6 bg-white'>
                            <div className='h-10 w-10 rounded-full bg-pprimary text-white flex items-center justify-center text-xl'>
                                <MdSecurity />
                            </div>
                            <p className='text-3xl text-pblact font-semibold'>Security</p>
                            <span className='text-gray'>Ensuring the utmost protection of sensitive information through state-of-the-art security measures, earning the trust of our clients.</span>
                        </div>
                    </div>
                </div>
            </section>
            <section className='flex justify-center py-32' id='work'>
                <div className='max-w-7xl grid grid-cols-2 w-full px-6 gap-32'>
                    <div>
                        <img src={WorkImage} alt="" srcset="" className='rounded-3xl' />
                    </div>
                    <div className='flex flex-col  w-full px-6 gap-8'>
                        <p className='text-pprimary font-semibold'>Our Work:</p>
                        <span className='text-3xl text-pblact font-semibold'>We are driven by the following principles:</span>
                        <div className='flex flex-col gap-4 mt-10'>
                            <div className='py-12 px-6 rounded-2xl shadow border border-pprimary flex gap-6 items-center'>
                                <div className='p-2 rounded-full bg-pprimary text-white text-3xl'><GiProcessor className='' /></div>
                                <p className='text-2xl text-pprimary font-semibold'>Processes Optimization</p>
                            </div>
                            <div className='py-12 px-6 rounded-2xl shadow border border-pprimary flex gap-6 items-center'>
                                <div className='p-2 rounded-full bg-pprimary text-white text-3xl'><AiOutlineCloudSync className='' /></div>
                                <p className='text-2xl text-pprimary font-semibold'>Organizations Digitization</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='flex justify-center py-8 border-t border-green-50'>
                <div className='max-w-7xl flex items-center justify-between w-full px-6 gap-32 font-semibold'>
                    <div className=' flex justify-center items-center gap-12 text-pprimary'>
                        <div className='flex gap-4 items-center'>
                            <BiSolidPhone />
                            <p>+ 250 788 30 42 69</p>
                        </div>
                        <div className='flex gap-4 items-center'>
                            <MdEmail />
                            <p>techdalltd@gmail.com</p>
                        </div>
                    </div>
                    <div>
                        <p className='font-semibold'>Copyright ©2023 <span className='text-pprimary'>DALTECH</span></p>
                    </div>
                </div>
            </section>
        </div>
    )
}
