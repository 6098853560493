import React, { Component } from 'react';
import { ViewItem } from "../redux/actions/itemAction";
import { connect } from 'react-redux';
import propTypes from 'prop-types';

export class View extends Component {
    state = {
        loading: false,
    };
    componentDidMount = async () => {
        this.setState({loading: true});
        const { viewItem } = this.props;
        await viewItem();
        this.setState({loading: true});
        console.log(this.props)
        console.log("kdhsjdhns")
    }
    showItem = (e) => {
        e.preventDefault();
        console.log(this.props)
    }
    render() {
        return (
            <div className="flex items-center justify-center w-screen h-screen px-12 xl:px-64 lg:px-48 md:px-24">
                <p>view </p>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    SaveItem: state.item,
})

export default connect(mapStateToProps, { ViewItem })(View);