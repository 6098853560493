import React, { useState } from 'react';
import BackgroundPattern from "../Assets/background-pattern.png"
import { AiFillCheckCircle } from "react-icons/ai"
import { HiChevronDoubleDown, HiChevronDoubleUp } from "react-icons/hi2"


function CollapsibleDiv({ title, isOpen, onClick, data, date }) {
    return (
        <div className='text-white'>


           
                <div className={`w-full p-6 borderGradient rounded-xl hover:border hover:bg-transparent zoom ${isOpen ? 'open' : ''}`}>
                    <button onClick={onClick} className='w-full flex items-center justify-between'>
                        <div className='text-left flex flex-col gap-1'>
                            <p className="text-2xl font-semibold">{title}</p>
                            <span className='text-textGray text-base font-normal ml-4'>{date}</span>
                        </div>

                        <div className='text-3xl text-radiant2'>
                            {
                                !isOpen ? <HiChevronDoubleDown /> : <HiChevronDoubleUp />
                            }
                        </div>
                    </button>
                    {isOpen && (
                        <div className="content mt-4 zoom">
                            {
                                data.map((d, i) => {
                                    return (
                                        <div className='flex gap-2 items-center my-2 ml-4 text-textPrimary text-base '>
                                            <AiFillCheckCircle className="text-radiant1" />
                                            <p key={i}>{d}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )}
                </div>
        </div>
    );
}

function Items() {
    const [sections, setSections] = useState([
        {
            title: "Aegis Trust Programmes MEIS", isOpen: false,
            date: "Nov 2022 - June 2023",
            data: [
                "Created the system deliverables, functionalities and timelines",
                "Supported the procurement committee to choose the right Consultant for the project",
                "I helped the Consultant to gather functional requirement by easing the communication between him and the stakeholders (AEGIS staff)",
                "By using the Agile methodology, I with the consultant, we broke the system into small sprints.",
                "Created a regular communication channel to ensure that we are up to date on the progress of the project (slack)",
                "Led sprin review meetings before user story approval.",
                "Led the staff training session and created the user-manual enabling the smooth adoption and minimizing disruption in the transition"
            ]
        },
        {
            title: "Genocide Archive physical Object digitalization", isOpen: false,
            date: "Dec 2022 - Now",
            data: [
                "Served as a servant leader, making the needs of the team a priority",
                "Facilitated the  sprint planning, daily scrum, sprint review and sprint retrospective meetings",
                "With the head of  Archive department, we created a year plan and broke the project into small (monthly) milestones to ensure a smooth transition.",
                "As they had a big dataset thet needed to imported (stored in excel), I cleaned the data to remove errors and harmonize the data structure",
                "Promoted open communication between the development team and product owner.",
                "Promote Self-Organization by encouraging and supporting self-organization within the development team. To help the team take ownership of the work and decision.",
                "Collected and provided visibility into relevant metrics and data, such as charts, velocity, and other performance measures.",
                "Create a user-manual for the stakeholders (AEGIS staff) to ease the transition."
            ]
        },
        {
            title: "Genocide Archive of Rwanda", isOpen: false,
            date: "July 2023 - Now",
            data: [
                "Work closely with the Gliffos team (consultant) who is revamping it",
                "Collaborates with stakeholders, including customers, end-users, and team members, to gather and understand requirements.",
                "Increased the space of our server (OVH) to accommodate the new videos and audios for the revempaed version",
                "Prioritizes the product backlog, which made sure that they were alligned with the Organisation value.",
                "Helped define the sprint goals, providing a clear focus for the team's work during the sprint.",
                "Participated in the sprint planning, review and retrospective meeting.",
                "Collected feedback from customers and stakeholders and incorporates it into the product backlog",
                "With the support of the consultant, created way to transfer Videos and audios from the local machines or OneDrive to the FTP serve using Filezilla.",
                "Supervise the work done and the remaining deliverable and the timeline"
            ]
        },
    ]);

    const handleToggle = (index) => {
        const updatedSections = [...sections];
        updatedSections.forEach((section, i) => {
            if (i === index) {
                section.isOpen = !section.isOpen;
            } else {
                section.isOpen = false;
            }
        });
        setSections(updatedSections);
    };
    return (
        <div className="w-full bg-gray">
            {/* <div className='px-8 lg:px-40 py-6'>
                    <div className='bg-blue h-20 w-full flex items-start justify-between px-16'>
                        <div className='flex items-center justify-center text-white text-lg font-normal h-full gap-12'>
                            <p>Home</p>
                            <p>Home</p>
                        </div>
                    </div>
                </div> */}
            <div className='bg-contain bg-no-repeat bg-center ' style={{ backgroundImage: `url(${BackgroundPattern})` }}>
                <div className='min-h-screen text-white w-full flex items-center justify-center '>
                    <div className='flex flex-col gap-16 text-center'>
                        <p className='font-bold text-7xl'>Bihogo Arsene</p>
                        <span className='text-3xl radiant-text'>Project Manager & Scrum master</span>
                    </div>
                </div>
            </div>
            <section className='flex justify-center' >
                <div className='w-4/5 flex flex-col gap-10'>
                    <div className='px-8 lg:px-40'>
                        <span className='text-textGray text-base font-normal'>Learn About</span>
                        <p className='text-white text-3xl font-normal'>Bihogo Arsene</p>
                    </div>
                    <div className=' lineGradient w-full' />
                    <div className='text-white px-8 lg:px-40'>
                        <p className='text-base'>I’m a skilled and focused IT expert with specialization in project management. I’m always eager to learn new things in this fast-paced industry, to improve productivity, speed, and quality. I’ve been contracting/freelancing for more than 5 years which helped me to acquire agile methodologies and team leadership skills. I can take full ownership of a project from start to finish or work alongside an existing team.</p>
                    </div>
                </div>
            </section>
            <section className='py-20 mt-24'>
                <div className='px-8 lg:px-40 '>
                    <p className='text-white text-3xl font-normal'>Past Experience</p>
                </div>
                <div className='text-white px-8 lg:px-40'>
                    <div className="my-12  flex flex-col gap-8">

                        {sections.map((section, index) => (
                            <CollapsibleDiv
                                key={index}
                                date={section.date}
                                title={section.title}
                                data={section.data}
                                isOpen={section.isOpen}
                                onClick={() => handleToggle(index)}
                            />
                        ))}
                    </div>
                </div>
            </section>
            <footer className='py-8 px-2 lg:px-40 border-t border-textGray'>
                <div className='w-full flex items-center justify-between text-textGray text-sm font-light gap-2'>
                    <p>Email: bhgarsene@gmail.com</p>
                    <p>© COPYRIGHT 2023</p>
                    <p>Phone number: +250 789 248 248</p>
                </div>
            </footer>
        </div>
    )
}


export default Items;