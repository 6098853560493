import { LOADING, SAVE_FAILED, SAVE_SUCCESS, GET_SUCCESS, GET_FAILED } from '../actions/types'

const initialState = {
    isLoading: false,
    error: '',
    saved: false,
    visitors: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING:
            return {
                ...state,
                loading: true,
                saved: false,
                error: ''
            }
        case SAVE_FAILED:
            return {
                ...state,
                loading: false,
                saved: false,
                error: action.payload
            }
        case SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                saved: true,
                error: '',
            }
        case GET_SUCCESS:
            return {
                ...state,
                loading: false,
                visitors: action.payload
            }
        case GET_FAILED:
            return {
                ...state,
                loading: false,
                visitors: action.payload
            }
        default:
            return {
                ...state,
            }
    }
}